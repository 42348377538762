
import { Action, Getter } from "vuex-class";
import { Component, Ref, Mixins } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit, FormSelect, FormPhone } from "@/components/forms";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { PatchPartnerPayload } from "@/store/modules/partner.store";
import { PartnerType } from "@/store/modules/partner.store";
import { Partner } from "@/store/modules/partner.store";
import { Office } from "@/store/modules/office.store";

@Component({
    components: {
        FormBase,
        FormInput,
        FormError,
        FormSelect,
        FormPhone,
        FormSubmit,
    },
})
export default class PageAdminUsersCreate extends Mixins(HasFormErrors) {
    @Getter("office/all") offices!: Office[];

    @Action("partner/patch") patchPartner!: (payload: PatchPartnerPayload) => Partner;
    @Action("partner/read") readPartner!: (payload: number) => Partner;

    @Ref() form!: FormClass;

    partnerTypes = PartnerType;

    partner: Partner | null = null;

    payload: PatchPartnerPayload = {
        id: NaN,
        company_name: "",
        office_ids: [],
    };

    async mounted() {
        this.partner = await this.readPartner(Number(this.$route.params.id));

        this.payload = {
            id: this.partner.id,
            company_name: this.partner.company_name ?? null,
            office_ids: this.partner.offices?.map((o) => o.id) ?? [],
        };
    }

    get officeOptions() {
        return this.offices.map((o) => {
            return {
                label: o.name,
                value: o.id,
            };
        });
    }

    get partnerTypeOptions() {
        return Object.values(PartnerType).map((type: PartnerType) => ({ value: type, label: this.$t(`views.partner.option_name.${type}_title`) }));
    }

    async submit(form: FormClass) {
        try {
            await this.patchPartner(this.payload);

            this.$toast.open({ message: "Partner aangepast", type: "success" });
        } catch (e: any) {
            this.errorResponse = this.formatErrors(e);
        } finally {
            form.reset();
        }
    }
}
